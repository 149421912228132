/* RichTextEditor.css */
.custom-quill {
    background-color: white; /* Set the background color to white */
    opacity: 1; /* Set opacity to 1 to make it not transparent */
    border: none !important;
    border-radius: 8px;
}

/* put this on a page combined with the container class for a page 
with a short amount of text to make sure the footer isnt half way up 
the page (its not related to quill can be used for any page) */
.container-page-creator {
    display: grid;
    grid-template-rows: auto 1fr auto;
    min-height: 73vh;
  }
  